import React from 'react';
import { Button, Sheet } from '@smwb/summer-ui';
import { SelectField, TextField, Toggle } from '@smwb/summer-ui/dist/connects/rff';
import { Form, FormProps } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Engine } from '@baseModel/engine/engine';
import { Models } from '@baseModel/engine/types';
import { RelationMetaModel } from '@baseModel/metaModel/relationMetaModel';
import { useModel } from '@baseModel/hooks/useModel';
import { Entity } from '@baseModel/model/entity';
import { Relation } from '@baseModel/model/relation';
import { EntityMetaModel } from '@baseModel/metaModel/entityMetaModel';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { validationField } from '../utils';
import { ActionModel, ActionType, FormData, FormFields } from '../types';
import { useTypedSelector } from '../../../redux/types';
import { clear } from '../redux/actions';
import s from './addOrUpdateShellForm.module.less';
import uniqueId from 'lodash/uniqueId';
import { SimpleValueType } from '@baseModel/types/simpleValueType';

const engine: Engine = Engine.getInstance();

export interface AddOrUpdateShellFormProps {
  configId?: string;
}

function getItems(metaModelName?: string) {
  const entityIds = engine.getEntitiesIdsByMetaName(metaModelName);
  return entityIds.map((entityId) => {
    const entity = engine.getEntityById(entityId);
    const fieldName = entity.getFieldValue('__name');
    const description = entity.getFieldValue('description');
    const text = `${fieldName} (id: ${entityId})`;

    return {
      key: entityId,
      text,
      value: entityId,
      helperText: description as string
    };
  });
}

export function AddOrUpdateShellForm({ configId }: AddOrUpdateShellFormProps) {
  const dispatch = useDispatch();

  const isMobile = useMediaQuery('(max-width: 768px)');
  const onClose = () => {
    dispatch(clear());
  };
  const {
    type: actionType,
    modelType,
    modelId,
    metaModelName = '',
    tableId = '',
    nestedTable
  } = useTypedSelector((state) => state.app.tableView.actions);

  const id = actionType === ActionType.Add ? undefined : modelId;
  const actionModel = useModel(id, modelType) as Entity | Relation | undefined;
  const isOpen =
    !!configId && configId === tableId && (actionType === ActionType.Add || actionType === ActionType.Update);
  const isEntity = modelType === Models.Entity;
  const metaModel = useModel(
    metaModelName,
    modelType === Models.Entity ? Models.EntityMetaModel : Models.RelationMetaModel
  ) as EntityMetaModel | RelationMetaModel | undefined;

  const onSave: FormProps<FormData>['onSubmit'] = (data) => {
    let model: ActionModel;

    if (!metaModel) {
      console.error(`metaModel is undefined`);
      return;
    }

    if (actionType === ActionType.Update && actionModel) {
      model = actionModel;
    } else if (actionType === ActionType.Add) {
      if (metaModel instanceof EntityMetaModel) {
        model = new Entity(metaModel);
      } else {
        model = new Relation(metaModel);
      }
    } else {
      return;
    }

    try {
      const fields = data[FormFields.Fields];

      for (const key in fields) {
        model.setFieldValue(key, fields[key]);
      }

      if (model instanceof Relation) {
        const relations = data[FormFields.Relations];

        for (const key in relations) {
          model.setRelationValue(key, relations[key]);
        }
      }

      if (actionType === ActionType.Add) {
        model.setFieldValue('__type', model.getMetaModel().getName());

        if (model instanceof Entity) {
          engine.addEntity(model);

          if (nestedTable !== undefined && modelId !== undefined && nestedTable.endTargetName) {
            const metaRelationModel = engine.getMetaRelationByName(nestedTable.relation);
            const relation = new Relation(metaRelationModel);
            const id = uniqueId('md-' + Date.now().toString());
            const idName = relation.getIdFieldName();

            if (!idName) {
              console.error(`id name is unknown in metamodel`);
              return;
            }
            relation.setFieldValue(idName, id);
            relation.setRelationValue(nestedTable.endTargetName, model.getId() as string);
            relation.setRelationValue(nestedTable.endSelfName, modelId);

            engine.addRelation(relation);
          }
        } else {
          engine.addRelation(model);
        }
      }

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getHeadingShell = () => {
    const prefix = actionType === ActionType.Add ? 'Создание' : 'Редактирование';
    const postfix = isEntity ? 'модели' : 'связи';

    return [prefix, postfix].join(' ');
  };

  const fields = React.useMemo(() => {
    const fields: React.ReactElement[] = [];

    if (metaModel === undefined) {
      return fields;
    }

    const fieldNames = metaModel.getFieldNames() || [];

    for (const fieldName of fieldNames) {
      const metaFieldValue = metaModel.getFieldValue(fieldName);

      if (metaFieldValue === undefined) {
        continue;
      }

      const isId = fieldName === metaModel.getIdFieldName();
      const isAddId = actionType === ActionType.Add && isId;
      const isUpdateId = actionType === ActionType.Update && isId;

      let defaultValue: SimpleValueType = '';

      if (isAddId) {
        defaultValue = uniqueId('md-' + Date.now().toString());
      } else if (ActionType.Update && actionModel !== undefined) {
        const updateValue = actionModel.getFieldValue(fieldName);
        if (updateValue !== undefined) {
          defaultValue = updateValue;
        }
      }

      const name = [FormFields.Fields, fieldName].join('.');

      if (metaFieldValue.type === 'boolean') {
        fields.push(
          <Toggle
            key={fieldName}
            initialValue={defaultValue === true || defaultValue === 'true' ? 1 : 0}
            readOnly={isUpdateId}
            validate={validationField(metaFieldValue)}
            name={name}
            autoComplete="off"
            label={metaFieldValue['display-name']}
            disabled={isAddId}
          />
        );
      } else {
        fields.push(
          <TextField
            key={fieldName}
            initialValue={defaultValue as string}
            readOnly={isUpdateId}
            validate={validationField(metaFieldValue)}
            name={name}
            autoComplete="off"
            label={metaFieldValue['display-name']}
            disabled={isAddId}
          />
        );
      }
    }

    if (metaModel instanceof RelationMetaModel) {
      const relationNames = metaModel.getRelationNames();

      for (const relationName of relationNames) {
        const metaRelationValue = metaModel.getRelationValue(relationName);

        if (metaRelationValue === undefined) {
          continue;
        }

        let defaultValue = '';

        if (ActionType.Update && actionModel !== undefined) {
          const updateValue = actionModel?.getRelationValue(relationName);

          if (updateValue !== undefined) {
            defaultValue = String(updateValue);
          }
        } else if (nestedTable && !nestedTable.endTargetName && modelId) {
          defaultValue = modelId;
        }
        const name = [FormFields.Relations, relationName].join('.');

        fields.push(
          <SelectField
            disabled={nestedTable && !nestedTable.endTargetName && relationName === nestedTable.endSelfName}
            key={relationName}
            initialValue={defaultValue}
            name={name}
            validate={validationField(metaRelationValue)}
            label={metaRelationValue['display-name']}
            items={getItems(metaRelationValue.type)}
            menuMaxHeight={220}
          />
        );
      }
    }

    return fields;
  }, [metaModel, actionType, actionModel, nestedTable, modelId]);

  return (
    <Sheet isOpen={isOpen} onClose={onClose} variant="modal" placement={isMobile ? 'bottom' : 'right'} size="450px">
      <div className={s.heading}>{getHeadingShell()}</div>
      <Form
        onSubmit={onSave}
        render={({ handleSubmit, submitting }) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <form className={s.stack} onSubmit={handleSubmit}>
            {fields}
            <Button variant="contained" type="submit" disabled={submitting}>
              Сохранить
            </Button>
          </form>
        )}
      />
    </Sheet>
  );
}

import { DocumentEvent, DocumentEventData, EventType } from '@baseModel/document/document';
import {
  JsonDocumentActionBlock,
  JsonDocumentBlocks,
  JsonDocumentBlockType,
  JsonDocumentCustomNodeBlock,
  JsonDocumentDiagramBlock,
  JsonDocumentMarkdownBlock,
  JsonDocumentTableBlock,
  ModelType
} from '@baseModel/types/jsonDescription';
import { emitYaml } from '@components/yamlEditor/plugins/yamlBind/editorInternalChangesSubscriber';
import { EmitType, ModelPaths } from '../types';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { YamlModelState } from '@components/yamlEditor/yamlModelState';
import { Engine } from '@baseModel/engine/engine';
import { BlockType } from '@baseModel/document/blocks/types';
import { TableBlockValue } from '@baseModel/document/blocks/table';
import { Models } from '@baseModel/engine/types';
import { DiagramBlockValue } from '@baseModel/document/blocks/diagram';
import { CustomNodeValue } from '@baseModel/document/blocks/customNode';
import omit from 'lodash/omit';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

const yamlModelState = YamlModelState.getInstance();
const engine = Engine.getInstance();

function addOrUpdateBlock(value: DocumentEvent<DocumentEventData>) {
  const isAdd = value.event === EventType.add;
  const type = isAdd ? EmitType.Insert : EmitType.Update;

  switch (value.type) {
    case BlockType.markdown: {
      const valueData = (value.data as string) ?? '';

      const data: JsonDocumentMarkdownBlock = {
        type: JsonDocumentBlockType.markdown,
        id: value.id,
        value: valueData
      };

      emitYaml(type, ModelPaths.Document, value.position, data);
      return;
    }
    case BlockType.table: {
      const valueData = value.data as TableBlockValue;

      const data: JsonDocumentTableBlock = {
        type: JsonDocumentBlockType.table,
        id: value.id,
        'model-type': valueData.modelType === Models.Entity ? ModelType.entities : ModelType.relations,
        'row-type': valueData.rowType,
        columns: valueData.columns
      };

      if (valueData.showLink) {
        data['show-link'] = {
          table: valueData.showLink.table,
          'end-self': valueData.showLink.endSelf,
          'end-target': valueData.showLink.endTarget,
          relation: valueData.showLink.relation
        };

        const childShowLink = valueData.showLink.showLink;

        if (childShowLink) {
          data['show-link']['show-link'] = {
            table: childShowLink.table,
            'end-self': childShowLink.endSelf,
            'end-target': childShowLink.endTarget,
            relation: childShowLink.relation
          };
        }
      }

      if (valueData.filter) {
        data['filter'] = valueData.filter;
      }

      emitYaml(type, ModelPaths.Document, value.position, data);
      return;
    }

    case BlockType.diagram: {
      const valueData = value.data as DiagramBlockValue;

      const data: JsonDocumentDiagramBlock = {
        type: JsonDocumentBlockType.diagram,
        id: value.id,
        'allowed-types': valueData.allowedTypes,
        base64: valueData.base64,
        contains: valueData.contains
      };

      emitYaml(type, ModelPaths.Document, value.position, data);
      return;
    }

    case BlockType.customNode: {
      const valueData = value.data as CustomNodeValue;
      const data: JsonDocumentCustomNodeBlock = {
        ...omit(valueData, 'entryPoint'),
        'entry-point': valueData.entryPoint,
        type: JsonDocumentBlockType.customNode,
        id: value.id
      };
      emitYaml(type, ModelPaths.Document, value.position, data);
      return;
    }

    case BlockType.action: {
      const valueData = value.data as JsonDocumentActionBlock;

      const data: JsonDocumentActionBlock = {
        id: value.id,
        type: JsonDocumentBlockType.action,
        value: valueData.value,
        title: valueData.title
      };

      emitYaml(type, ModelPaths.Document, value.position, data);
      return;
    }
  }

  const Widget = getWidget(value.type);

  if (Widget) {
    const widget = new Widget();
    const Block = widget.getBlock();
    const valueData = value.data as JsonDocumentBlock;
    const data = Block.getValueInJSONForYAML({
      id: value.id,
      type: widget.getType(),
      config: valueData
    });

    emitYaml(type, ModelPaths.Document, value.position, data);
    return;
  }

  console.warn('Неизвестный тип', value.type, value);
}

export function baseModelDocumentSubscriber() {
  const document = engine.getDocument();

  return document.subscribe(
    EventType.all,
    (value) => {
      switch (value.event) {
        case EventType.add:
        case EventType.update:
          addOrUpdateBlock(value);
          // eslint-disable-next-line no-case-declarations
          const block = {
            type: <JsonDocumentBlockType>(<unknown>value.type),
            id: value.id,
            ...(<Omit<JsonDocumentBlocks, 'type' | 'id'>>value.data ?? {})
          };
          yamlModelState.setDocument((oldState) => {
            if (value.event === EventType.add) {
              oldState.splice(value.position, 0, <JsonDocumentBlocks>block);
            } else {
              oldState.splice(value.position, 1, <JsonDocumentBlocks>block);
            }
            return oldState;
          });
          break;
        case EventType.remove:
          emitYaml(EmitType.Remove, ModelPaths.Document, value.position);

          break;
        case EventType.sortIndex: {
          const block = document.getBlockById(value.id);

          if (block === undefined) {
            return;
          }

          yamlModelState.setDocument((oldState) => {
            const prevIndex = oldState.findIndex((el) => el.id == value.id);
            [oldState[prevIndex], oldState[value.position]] = [oldState[value.position], oldState[prevIndex]];
            return oldState;
          });

          const newValue = { ...value, data: block.getValue() };

          addOrUpdateBlock(newValue);

          break;
        }
      }
    },
    EDITOR_SENDER
  );
}

import { AvailableBlock } from '@baseModel/document/blocks/types';
import { Markdown } from '@baseModel/document/blocks/markdown';
import { Table } from '@baseModel/document/blocks/table';
import { Diagram } from '@baseModel/document/blocks/diagram';
import { CustomNode } from '@baseModel/document/blocks/customNode';
import { Action } from '@baseModel/document/blocks/action';
import { EDITOR_SENDER } from '@components/yamlEditor/const';
import { EventEditorDocumentObserver } from '@components/yamlEditor/plugins/yamlBind/types';
import { Engine } from '@baseModel/engine/engine';
import {
  JsonDocumentActionBlock,
  JsonDocumentCustomNodeBlock,
  JsonDocumentDiagramBlock,
  JsonDocumentMarkdownBlock,
  JsonDocumentTableBlock
} from '@baseModel/types/jsonDescription';
import { blockMapping } from '@baseModel/document/document';
import { getWidget } from '@components/markdownEditor/dataDisplayWidgets/utils';
import { JsonDocumentBlock } from '@components/markdownEditor/dataDisplayWidgets/baseWidget/block/block';

const engine = Engine.getInstance();

export function editorDocumentBlockListener(event: EventEditorDocumentObserver) {
  const {
    changes: { after },
    meta
  } = event;

  const document = engine.getDocument();
  const blocks = document.getBlocks();
  const hasBlock = blocks.find((block) => meta.id === block.getId());

  if (hasBlock && meta.deleted) {
    document.removeBlockById(hasBlock.type, meta.id, EDITOR_SENDER);
    return;
  }

  const isAdd = !hasBlock && after !== undefined;
  let block: AvailableBlock | undefined;

  if (isAdd) {
    if (!after?.type) {
      console.error('type не указан');
      return;
    }

    const newBlockClass = blockMapping[after.type];
    const Widget = getWidget(after.type);

    if (newBlockClass === undefined && Widget === undefined) {
      console.error(`block с типом ${after.type} не найден`);
      return;
    }

    if (newBlockClass) {
      block = new newBlockClass(meta.id);
    } else {
      if (Widget) {
        const widget = new Widget();
        const WidgetBlock = widget.getBlock();

        block = new WidgetBlock(widget.valueSchema, widget.getType(), meta.id);
      }
    }
  } else {
    block = hasBlock;
  }

  // Только изменили сортировку
  if (block && !after && !isAdd && meta.changedSortIndex !== undefined) {
    document.setBlockSortIndex(meta.id, meta.changedSortIndex, EDITOR_SENDER);
    return;
  }

  if (block instanceof Markdown) {
    block.setValue(Markdown.getValueFromJSON(<JsonDocumentMarkdownBlock>after || ''), EDITOR_SENDER);
  } else if (block instanceof Table) {
    block.setValue(Table.getValueFromJSON(<JsonDocumentTableBlock>after || ''), EDITOR_SENDER);
  } else if (block instanceof Diagram) {
    block.setValue(Diagram.getValueFromJSON(<JsonDocumentDiagramBlock>after || ''), EDITOR_SENDER);
  } else if (block instanceof CustomNode) {
    block.setValue(CustomNode.getValueFromJSON(<JsonDocumentCustomNodeBlock>after || ''), EDITOR_SENDER);
  } else if (block instanceof Action) {
    block.setValue(Action.getValueFromJSON(<JsonDocumentActionBlock>after || ''), EDITOR_SENDER);
  } else if (block) {
    const Widget = getWidget(block.type);

    if (Widget) {
      const widget = new Widget();
      const Block = widget.getBlock();

      block.setValue(Block.getConfigFromJSON(<JsonDocumentBlock>after || ''), EDITOR_SENDER);
    }
  }

  if (isAdd && block) {
    document.addBlock(block, meta.changedSortIndex, EDITOR_SENDER);
    return;
  }
}
